<template>
  <div>
    <a-card title="测量任务">
      <a-row :gutter="[12, 12]">
        <a-col :span="24" style="width: 256px">
          <a-input v-model="searchForm.search" placeholder="任务编号" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" style="width: 256px">
          <a-select v-model="searchForm.production_line" placeholder="生产线" allowClear style="width: 100%" @change="search">
            <a-select-option value="a">名创 A</a-select-option>
            <a-select-option value="b">名创 B</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" style="width: 100px">
          <a-button type="primary" icon="search" :loading="dataLoading" @click="search" style="width: 100%">查询</a-button>
        </a-col>
        <a-col :span="24" style="width: 200px">
          <a-button-group>
            <a-button icon="download" @click="exportExcel">导出</a-button>
          </a-button-group>
        </a-col>

        <a-col :span="24" style="width: 100px; float: right">
          <a-button type="primary" icon="plus" style="width: 100%" @click="createModalVisible = true">新增</a-button>
        </a-col>

        <a-col :span="24">
          <a-table
            rowKey="id"
            size="small"
            :columns="columns"
            :dataSource="dataItems"
            :loading="dataLoading"
            :pagination="pagination"
            :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            @change="changeTable"
          >
            <template slot="status" slot-scope="value, item">
              <a-badge v-if="item.status === 'completed'" status="success" text="已完成" />
              <a-badge v-else-if="item.status === 'in_progress'" status="processing" text="进行中" />
              <a-badge v-else status="default" text="已关闭" />
            </template>
            <template slot="action" slot-scope="value, item">
              <table-action :item="item" @update="update" />
            </template>
            <template slot="expandedRowRender" slot-scope="item">
              <ExpandTable :items="item.measurement_record_items" />
            </template>
          </a-table>
        </a-col>
      </a-row>
    </a-card>

    <create-form-modal v-model="createModalVisible" @create="create" @cancel="createModalVisible = false" />
    <a-modal v-model="exportLoading" :footer="null" :maskClosable="false" :closable="false">
      <div><a-spin style="margin-right: 12px" />正在导出中, 请等待...</div>
    </a-modal>
  </div>
</template>

<script>
import { insertItem, replaceItem, removeItem } from "@/utils/functions";
import { measurementTaskList, measurementTaskExport } from "@/apis/calibration";
import { exportPDF } from "@/utils/excel";

export default {
  components: {
    CreateFormModal: () => import("./CreateFormModal"),
    TableAction: () => import("./TableAction"),
    ExpandTable: () => import("./ExpandTable"),
  },
  data() {
    return {
      searchForm: { search: "", page: 1, ordering: undefined },
      pagination: { current: 1, total: 0, pageSize: 16 },
      dataLoading: false,

      // Table
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_value, _item, index) => index + 1,
        },
        {
          title: "任务编号",
          dataIndex: "number",
        },
        {
          title: "生产线",
          dataIndex: "production_line_display",
        },
        {
          title: "状态",
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 80,
          scopedSlots: { customRender: "action" },
        },
      ],
      dataItems: [],

      createModalVisible: false,
      selectedRowKeys: [],
      exportLoading: false,
    };
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    list() {
      this.dataLoading = true;
      measurementTaskList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.dataItems = data.results;
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    create(item) {
      this.dataItems = insertItem(this.dataItems, item);
    },
    update(item) {
      this.dataItems = replaceItem(this.dataItems, item);
    },
    destroy(item) {
      this.dataItems = removeItem(this.dataItems, item);
    },
    changeTable(pagination, _filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    exportExcel() {
      if (this.selectedRowKeys.length === 0) {
        this.$message.warning("请选择导出的测量任务");
        return;
      }

      this.exportLoading = true;
      measurementTaskExport({ ids: this.selectedRowKeys })
        .then((response) => {
          exportPDF(response, "测量报告");
        })
        .finally(() => {
          this.exportLoading = false;
        });
    },
  },
  mounted() {
    this.list();
  },
};
</script>

<style scoped></style>
